import { StyleSheet } from "react-native";

/**
 * Returns the width of a hairline border which can be customized for different
 * platforms as needed
 *
 * Note: if we end up with more custom border tokens this can be
 * moved accordingly.
 *
 * @returns The width of a hairline border.
 */
export const hairline = StyleSheet.hairlineWidth;
