import { createStorage } from "./storage";

// Auth-related storage
export const authStorage = createStorage({ namespace: "medbillai-auth-state" });

// App download Web storage
export const downloadStorage = createStorage({
  namespace: "medbillai-downloads",
});

// Add other storage instances as needed
// export const someOtherStorage = createStorage({ namespace:
// "some-other-namespace" });
