import { type FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  type ButtonProps,
  type ColorTokens,
  Spinner,
  Stack,
  type ThemeName,
  XStack,
} from "tamagui";

import { type IconProps } from "@tamagui/helpers-icon";

import { HapticsService } from "../utils";

export type LoadingButtonProps = ButtonProps & {
  theme?: ThemeName;
  loading?: boolean;
  gradientOff?: boolean;
  icon?: FunctionComponent<IconProps>;
  stickyLoading?: boolean;
  disableHaptic?: boolean;
  customHaptic?: () => void;
  bold?: boolean;
};

/**
 * This is our main button component. By default we use our primary gradient
 * use gradientOff to turn off the gradient and then apply any custom colors
 * or theming you want. To include an icon simply use the icon prop and pass.
 *
 * StickyLoading: sometimes the loading prop finishes put pushing a new screen is slow, when we know there cant be an error
 * we can use stickyLoading to keep the loading state for extra time. This should probably be improved in the future open to
 * ideas
 */
export const LoadingButton = (props: LoadingButtonProps) => {
  const {
    loading,
    stickyLoading,
    disableHaptic,
    customHaptic,
    disabled,
    icon: Icon,
    color = "$color",
    height = 50,
    bold = true,
    theme = "granted",
    size = "$4",
    textProps,
    ...rest
  } = props;
  const [sticky, setSticky] = useState(loading);
  const haptic =
    customHaptic !== undefined ? customHaptic : HapticsService.selection;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (stickyLoading && sticky == true) {
      timeoutId = setTimeout(() => {
        setSticky(loading);
      }, 200);
    } else {
      setSticky(loading);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loading, stickyLoading, sticky, setSticky]);

  return (
    <Button
      size={size}
      height={height}
      paddingVertical={0}
      paddingHorizontal={size}
      borderWidth={0}
      disabled={disabled}
      br={100}
      opacity={1}
      ai="center"
      jc="center"
      animation="200ms easeCubic"
      disabledStyle={{
        backgroundColor: "$cream7",
      }}
      hoverStyle={{
        backgroundColor: theme === "granted" ? "#0a2d70" : "$color5",
      }}
      pressStyle={{
        backgroundColor: theme === "granted" ? "#143980" : "$color4",
        scale: 0.95,
      }}
      focusStyle={{
        outlineWidth: 0,
        outlineStyle: "none",
      }}
      onPressOut={() => {
        !disableHaptic && void haptic();
      }}
      theme={theme}
      {...rest}
    >
      <XStack jc="center" ai="center" gap="$2" position="relative">
        {Icon ? (
          <Stack
            opacity={sticky ? 0 : 1}
            animation="quick"
            animateOnly={["opacity"]}
          >
            <Button.Icon scaleIcon={props.scaleIcon}>
              {/** @ts-expect-error color cant infer prop type correctly*/}
              <Icon color={disabled ? "$color3" : color}></Icon>
            </Button.Icon>
          </Stack>
        ) : null}
        <Button.Text
          unstyled
          opacity={sticky ? 0 : 1}
          animation="quick"
          animateOnly={["opacity"]}
          color={disabled ? "#ADA8A0" : color}
          fontWeight={bold ? "500" : "400"}
          {...textProps}
        >
          {props.children}
        </Button.Text>
        <Stack
          position="absolute"
          left={0}
          top={0}
          right={0}
          bottom={0}
          jc="center"
          ai="center"
          opacity={sticky ? 1 : 0}
          animateOnly={["opacity"]}
          animation="quick"
          zIndex={100}
        >
          <Spinner
            f={0}
            color={color as ColorTokens}
            key="loading-spinner"
            y={0}
            size="small"
            animation="quick"
          />
        </Stack>
      </XStack>
    </Button>
  );
};

export const TextButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      bw={0}
      backgroundColor="$background0"
      pressStyle={{
        backgroundColor: "$cream8",
        scale: 0.95,
      }}
      hoverStyle={{
        backgroundColor: "$backgroundTertiary",
      }}
      color="$textSecondary"
      w="100%"
      {...props}
    />
  );
};

export const SecondaryButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      bw={0}
      backgroundColor="$groupedSecondary"
      pressStyle={{
        backgroundColor: "$strokePrimary",
        scale: 0.95,
      }}
      hoverStyle={{
        backgroundColor: "$strokePrimary",
      }}
      color="$navy"
      br="$10"
      {...props}
    />
  );
};

export const OutlinedButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      bw={1}
      borderColor="$strokeSecondary"
      backgroundColor="transparent"
      pressStyle={{
        backgroundColor: "transparent",
        scale: 0.95,
      }}
      color="$background"
      hoverStyle={{
        bg: "transparent",
      }}
      {...props}
    />
  );
};
