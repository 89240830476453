import type { LDContext } from "@launchdarkly/js-client-sdk-common";
import { LDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";

import type { Flag } from "@medbillai/utils/flag";

import {
  FlagContext,
  type FlagProviderProps,
  type LDIdentifyFunction,
  type TypeOfResult,
  ldLogger,
  retryIdentify,
  useFlagContext,
  useFlagContextSetup,
} from "./shared";

const clientSideId = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID;

export const FlagProvider = ({ children }: FlagProviderProps) => {
  const { isReady, startIdentify, finishIdentify } = useFlagContextSetup();

  return (
    <FlagContext.Provider value={{ isReady, startIdentify, finishIdentify }}>
      <LDProvider
        clientSideID={clientSideId ?? ""}
        options={{
          logger: ldLogger,
          application: { id: "medbillai-next" },
        }}
        timeout={3}
      >
        {children}
      </LDProvider>
    </FlagContext.Provider>
  );
};

export const useLaunchDarklyIdentify = () => {
  const client = useLDClient();
  const { startIdentify, finishIdentify } = useFlagContext();

  const identify = useCallback<LDIdentifyFunction>(
    async (context: LDContext | ((prevContext?: LDContext) => LDContext)) => {
      if (!client) {
        // This is common on web, where the client is asynchronously
        // initialized, which requires a call to LD. The auth provider includes
        // `identify` in its `useEffect`, so it will automatically retry after
        // the client is available and the identify callback updates.
        return;
      }
      try {
        startIdentify();
        const existingContext = client ? client.getContext() : undefined;
        const newContext =
          typeof context === "function"
            ? context(existingContext as LDContext | undefined)
            : context;

        await retryIdentify(async () => {
          await client.identify(newContext);
        });
      } finally {
        // Need to be defensive here to make sure we always signal readiness
        // upon completion, even if the identify call failed.
        finishIdentify();
      }
    },
    // client doesn't actually change reference as the context updates etc, so
    // this will prevent the callback from ever being recreated.
    [client, startIdentify, finishIdentify],
  );
  return identify;
};

// On web, we don't support a true live-updating hook in the same way that we do
// on native. This can be improved if/when we switch to the React SDK. For now,
// we just memoize the flag value so that it doesn't continuously re-retrieve it.

const forceType =
  <T,>(typeofString: TypeOfResult) =>
  (flag: Flag, defaultValue: T) => {
    const client = useLDClient();
    if (!client) {
      return defaultValue;
    }

    const out = client.variation(flag as string, defaultValue) as unknown;
    if (typeof out !== typeofString) {
      console.warn(
        "Type mismatch: expected",
        typeofString,
        "but got",
        typeof out,
        "returning defaultValue:",
        defaultValue,
      );
      return defaultValue;
    }
    return out as T;
  };

export const useBoolVariation = forceType<boolean>("boolean");
export const useStringVariation = forceType<string>("string");
export const useNumberVariation = forceType<number>("number");
