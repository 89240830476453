import { createTamagui, createTokens, setupDev } from "tamagui";

import { shorthands } from "@tamagui/shorthands";

import { animations } from "./config/animations";
import { bodyFont, fontSize, headingFont } from "./config/fonts";
import { media, mediaQueryDefaultActive } from "./config/media";
import * as themesIn from "./themes/theme-generated";
import { color } from "./themes/token-colors";
import { radius } from "./themes/token-radius";
import { size } from "./themes/token-size";
import { space } from "./themes/token-space";
import { zIndex } from "./themes/token-z-index";

// Hold down Option for a second to see some helpful visuals
setupDev({
  visualizer: true,
});

/**
 * This avoids shipping themes as JS. Instead, Tamagui will hydrate them from CSS.
 */
const themes =
  process.env.TAMAGUI_TARGET !== "web" || process.env.TAMAGUI_IS_SERVER
    ? themesIn
    : ({} as typeof themesIn);

export const config = createTamagui({
  themes,
  animations,
  // We only have a "light" theme, so this is not needed.
  shouldAddPrefersColorThemes: false,
  themeClassNameOnRoot: true,
  mediaQueryDefaultActive,
  // Use the default selection styles for now - we can override this later
  // if we like, e.g.:
  // selectionStyles: theme => ({
  //   backgroundColor: theme.navy4,
  //   color: theme.gray3,
  // }),
  shorthands,
  fonts: {
    heading: headingFont,
    body: bodyFont,
  },
  tokens: createTokens({
    color,
    radius,
    zIndex,
    space,
    size,
    fontSize,
  }),
  media,
  settings: {
    allowedStyleValues: "somewhat-strict",
    autocompleteSpecificTokens: "except-special",
    fastSchemeChange: false,
  },
});

export default config;
