import { AlertDialog, type DialogProps, XStack, YStack } from "tamagui";

import { hairline } from "../utils/hairline";
import { LoadingButton, TextButton } from "./LoadingButton";
import { Paragraph, Subheadline2 } from "./Typography";

export type AlertDialogNotificationProps = {
  title: string;
  message: string;
  // defaults to Ok
  acceptText?: string;
  // defaults to Cancel
  cancelText?: string;
  // defaults to true
  native?: boolean;
  // defaults to false
  cancellable?: boolean;
  // defaults to false (if true, the cancel button will be thin)
  cancelThin?: boolean;
  onAccept?: () => void;
};

/*
 * Implements a simple native AlertDialog
 * see https://tamagui.dev/docs/components/alert-dialog
 */
export const AlertDialogNotification = ({
  title,
  message,
  acceptText = "Ok",
  native = true,
  open,
  onOpenChange,
  onAccept,
  cancellable = false,
  cancelThin = false,
}: AlertDialogNotificationProps & DialogProps) => {
  return (
    <AlertDialog native={native} open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger />
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          key="overlay"
          animation="quick"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <AlertDialog.Content
          elevate
          key="content"
          animation={[
            "quick",
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          x={0}
          scale={1}
          opacity={1}
          y={0}
          backgroundColor="$background"
          borderRadius="$10"
          borderWidth={hairline}
          borderColor="$strokeSecondary"
        >
          <YStack px="$6" py="$5" gap="$3">
            <AlertDialog.Title>
              <Subheadline2>{title}</Subheadline2>
            </AlertDialog.Title>
            <AlertDialog.Description>
              <Paragraph theme="alt1">{message}</Paragraph>
            </AlertDialog.Description>
            <XStack gap="$3" justifyContent="flex-end" pt="$2">
              {cancellable ? (
                cancelThin ? (
                  <AlertDialog.Action asChild onPress={() => onOpenChange}>
                    <TextButton f={1}>Cancel</TextButton>
                  </AlertDialog.Action>
                ) : (
                  <AlertDialog.Cancel asChild>
                    <TextButton f={1}>Cancel</TextButton>
                  </AlertDialog.Cancel>
                )
              ) : null}
              <AlertDialog.Action asChild onPress={onAccept}>
                <LoadingButton
                  f={1} // Button needs with even tho flex is applied
                  width="100%"
                >
                  {acceptText}
                </LoadingButton>
              </AlertDialog.Action>
            </XStack>
          </YStack>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog>
  );
};
