import {
  type Dispatch,
  type Reducer,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";

import { authStorage } from "../storage";

export interface Profile {
  name?: string;
  email: string;
}
export type ProfileData = Profile & { isAdmin: boolean };

export interface User {
  id: string;
  isAdmin: boolean;
  isShadow: boolean | undefined;
  adminUserId?: string;
  profile?: Profile;
}

export interface AuthState<TUser extends User = User> {
  user: TUser | null;
  isAuthenticated: boolean;
  error: Error | null;
  // Set to true once the initial auth state has been loaded from storage.
  // At this stage, we have not confirmed the validity of the session token.
  // This is used internally to know when to fetch the user's profile.
  isHydrated: boolean;
  // Set to true once the auth state has fully loaded, including retrieving
  // state from storage, and validating the session token. Note that this
  // requires a roundtrip to the API service, so there may be a short delay
  // at startup.
  isReady: boolean;
  // Used for Stytch magic login PKCE flow - this is a secret value
  // that we pass across a couple of requests.
  codeVerifier: string | null;
  // Last time a user was active on the app before it was pushed in the background.
  lastActiveTime: number | null;
  // Set to true once the user has registered biometrics for the first time,
  // and made it through the onboarding flow.
  hasRegisteredBiometrics: boolean;
  // Set to true once the user has finished the onboarding flow.
  // TODO consider moving this into the flow control logic and persisting there
  // with zustand middleware
  hasFinishedOnboarding: boolean;
  // Set to true once we have checked the user's identity via biometrics at startup.
  // If the user was active recently, this may be set to true without the user
  // having to re-check.
  isIdentityChecked: boolean;
  // Set to the error type if the last identity check errored. This is used to
  // keep the screen lock active until a successful check has been made.
  identityCheckError: string | null;
  // We lock the screen when the app goes to the background, in order to protect
  // sensitive information.
  isScreenLocked: boolean;
  // At startup, if the user fails the first biometrics check, we want to
  // force the screen lock to appear, even though it would normally be hidden
  // when isIdentityChecked is false.
  forceScreenLock: boolean;
  // In some cases we do not want to show the screen lock. For example when using camera upload
  // which is outside of the app and would trigger the lock screen
  isScreenLockSuppressed: boolean;
}

export const initialAuthState: AuthState<User> = {
  user: null,
  isAuthenticated: false,
  error: null,
  isHydrated: false,
  isReady: false,
  codeVerifier: null,
  lastActiveTime: null,
  hasRegisteredBiometrics: false,
  hasFinishedOnboarding: false,
  isIdentityChecked: false,
  identityCheckError: null,
  isScreenLocked: true,
  forceScreenLock: false,
  isScreenLockSuppressed: false,
};

export type Action<TUser extends User = User> =
  | { type: "APP_IN_BACKGROUND" }
  | {
      type: "STORAGE_REHYDRATED";
      user: TUser | null;
      isAuthenticated: boolean;
      codeVerifier: string | null;
      lastActiveTime: number | null;
      hasRegisteredBiometrics: boolean;
      hasFinishedOnboarding: boolean;
    }
  | { type: "PROFILE_LOADED"; profile: ProfileData }
  | { type: "AUTH_READY" }
  | { type: "LOGIN_REQUESTED" }
  | { type: "LOGIN_CANCELED" }
  | {
      type: "LOGIN_SUCCEEDED";
      user: TUser | null;
    }
  | { type: "SHADOW_LOGIN"; user: TUser | null }
  | { type: "LOGOUT" }
  | { type: "REFRESH_FAILED" }
  | { type: "UNLOCK_SCREEN" }
  | { type: "IDENTITY_CHECKED" }
  | { type: "IDENTITY_CHECK_FAILED"; error: string; forceScreenLock?: boolean }
  | { type: "ERROR"; error: Error }
  | { type: "CODE_VERIFIER"; codeVerifier: string | null }
  | { type: "SCREEN_LOCK_OVERRIDE"; isScreenLockSuppressed: boolean }
  | { type: "FINISHED_ONBOARDING"; hasFinishedOnboarding: boolean };

export const reducer = <TUser extends User = User>(
  state: AuthState<TUser>,
  action: Action<TUser>,
): AuthState<TUser> => {
  switch (action.type) {
    case "APP_IN_BACKGROUND":
      return {
        ...state,
        lastActiveTime: Date.now(),
        isScreenLocked: true,
      };
    case "STORAGE_REHYDRATED":
      return {
        ...state,
        isHydrated: true,
        user: action.user,
        isAuthenticated: action.isAuthenticated,
        codeVerifier: action.codeVerifier,
        lastActiveTime: action.lastActiveTime,
        hasRegisteredBiometrics: action.hasRegisteredBiometrics,
        hasFinishedOnboarding: action.hasFinishedOnboarding,
      };
    case "PROFILE_LOADED": {
      const { isAdmin, ...profile } = action.profile;
      return {
        ...state,
        user: state.user && {
          ...state.user,
          isAdmin,
          profile: profile,
        },
      };
    }
    case "AUTH_READY":
      return {
        ...state,
        isReady: true,
      };
    case "LOGIN_REQUESTED":
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        lastActiveTime: Date.now(),
        hasRegisteredBiometrics: false,
        isIdentityChecked: false,
        error: null,
      };
    case "LOGIN_CANCELED":
      return {
        ...state,
      };
    case "LOGIN_SUCCEEDED":
      return {
        ...state,
        user: action.user,
        isAuthenticated: !!action.user,
        error: null,
        codeVerifier: null,
      };
    case "SHADOW_LOGIN":
      return {
        ...state,
        user: action.user,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: null,
        // Ensure storage-persisted state is reset.
        lastActiveTime: null,
        hasRegisteredBiometrics: false,
        isIdentityChecked: false,
        isScreenLocked: false,
        isScreenLockSuppressed: false,
        identityCheckError: null,
        forceScreenLock: false,
        hasFinishedOnboarding: false,
      };
    case "REFRESH_FAILED":
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: null,
      };
    case "UNLOCK_SCREEN":
      // Used to hide the lock screen at startup without changing ID check state,
      // if e.g. the user is not authenticated.
      return {
        ...state,
        isScreenLocked: false,
        forceScreenLock: false,
        identityCheckError: null,
      };
    case "IDENTITY_CHECKED":
      return {
        ...state,
        hasRegisteredBiometrics: true,
        isIdentityChecked: true,
        isScreenLocked: false,
        forceScreenLock: false,
        identityCheckError: null,
      };
    case "IDENTITY_CHECK_FAILED":
      return {
        ...state,
        isScreenLocked: true,
        identityCheckError: action.error,
        // Only override forceScreenLock if it's passed in the action.
        forceScreenLock: action.forceScreenLock ?? state.forceScreenLock,
      };
    case "ERROR":
      return {
        ...state,
        isReady: true,
        error: action.error,
      };
    case "CODE_VERIFIER":
      return {
        ...state,
        codeVerifier: action.codeVerifier,
      };
    case "SCREEN_LOCK_OVERRIDE":
      return {
        ...state,
        isScreenLockSuppressed: action.isScreenLockSuppressed,
      };
    case "FINISHED_ONBOARDING":
      return {
        ...state,
        hasFinishedOnboarding: action.hasFinishedOnboarding,
      };
  }
};

export const usePersistedAuthReducer = <TUser extends User = User>(
  initialState: AuthState<TUser>,
): {
  state: AuthState<TUser>;
  dispatch: Dispatch<Action<TUser>>;
  initialize: () => void;
} => {
  const [state, dispatch] = useReducer<
    Reducer<AuthState<TUser>, Action<TUser>>
  >(reducer, initialState);

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    void authStorage.set(
      "isAuthenticated",
      JSON.stringify(state.isAuthenticated),
    );
  }, [state.isAuthenticated]);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    if (state.user) {
      void authStorage.set("user", JSON.stringify(state.user));
    } else {
      void authStorage.remove("user");
    }
  }, [state.user]);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    if (state.codeVerifier) {
      void authStorage.set("codeVerifier", state.codeVerifier);
    } else {
      void authStorage.remove("codeVerifier");
    }
  }, [state.codeVerifier]);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    if (state.lastActiveTime) {
      void authStorage.set("lastActiveTime", state.lastActiveTime.toString());
    } else {
      void authStorage.remove("lastActiveTime");
    }
  }, [state.lastActiveTime]);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    void authStorage.set(
      "hasRegisteredBiometrics",
      state.hasRegisteredBiometrics ? "true" : "false",
    );
  }, [state.hasRegisteredBiometrics]);

  useEffect(() => {
    if (!initialized.current) {
      return;
    }
    void authStorage.set(
      "hasFinishedOnboarding",
      state.hasFinishedOnboarding ? "true" : "false",
    );
  }, [state.hasFinishedOnboarding]);

  const initialize = useCallback(async () => {
    if (initialized.current) {
      return;
    }
    let isAuthenticated = (await authStorage.get("isAuthenticated")) === "true";
    let user =
      (JSON.parse((await authStorage.get("user")) || "null") as TUser | null) ||
      null;
    const codeVerifier = (await authStorage.get("codeVerifier")) ?? null;
    if (user && !isAuthenticated) {
      user = null;
      await authStorage.remove("user");
    }
    if (isAuthenticated && !user) {
      isAuthenticated = false;
      await authStorage.set("isAuthenticated", "false");
    }
    // Make sure we don't load nonsensical state here for a logged-out user.
    const lastActiveTime = isAuthenticated
      ? (await authStorage.get("lastActiveTime")) ?? null
      : null;
    const hasRegisteredBiometrics =
      isAuthenticated &&
      (await authStorage.get("hasRegisteredBiometrics")) === "true";

    const hasFinishedOnboarding =
      isAuthenticated &&
      (await authStorage.get("hasFinishedOnboarding")) === "true";

    dispatch({
      type: "STORAGE_REHYDRATED",
      isAuthenticated,
      user,
      codeVerifier,
      lastActiveTime: lastActiveTime ? parseInt(lastActiveTime) : null,
      hasRegisteredBiometrics,
      hasFinishedOnboarding,
    });
    initialized.current = true;
  }, [dispatch]);

  return { state, dispatch, initialize };
};
