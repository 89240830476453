import { Easing } from "react-native-reanimated";

import { createAnimations } from "@tamagui/animations-moti";

export const animations = createAnimations({
  "100ms": {
    type: "timing",
    duration: 100,
  },
  "150ms easeOut": {
    type: "timing",
    duration: 150,
    easing: Easing.out(Easing.ease),
  },
  "150ms easeIn": {
    type: "timing",
    duration: 150,
    easing: Easing.in(Easing.ease),
  },
  "200ms": {
    type: "timing",
    duration: 200,
  },
  "200ms easeOut": {
    type: "timing",
    duration: 200,
    easing: Easing.out(Easing.ease),
  },
  // Bezier function courtesy of https://joebell.studio/craft/loading-disco/
  "200ms easeCubic": {
    type: "timing",
    duration: 200,
    easing: Easing.bezier(0.4, 0, 0.2, 1),
  },
  "300ms": {
    type: "timing",
    duration: 300,
    easing: Easing.in(Easing.ease),
  },
  "300ms ease": {
    type: "timing",
    duration: 300,
    easing: Easing.inOut(Easing.ease),
  },
  bouncy: {
    type: "spring",
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
  // Mimics IOS switch animations
  // (tight snappy and fast)
  bouncyTight: {
    type: "spring",
    damping: 15,
    mass: 0.5,
    stiffness: 200,
  },
  lazy: {
    type: "spring",
    damping: 20,
    stiffness: 60,
  },
  quick: {
    type: "spring",
    damping: 20,
    mass: 1.2,
    stiffness: 250,
  },
  // Mimics IOS modal animations speed
  // (snappy ease in)
  medium: {
    type: "spring",
    damping: 15,
    stiffness: 120,
    mass: 0.2,
  },
  tooltip: {
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
});
