import { Image, type ImageProps } from "@medbillai/ui";

// TODO: Remove after template / login flow ticket
export const GradientUnderlayImage = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/gradient-underlay.png`,
      }}
      height={100}
      width={150}
      {...props}
    />
  );
};

// TODO: Remove after template / login flow ticket
export const GradientBackgroundImage = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/gradientOverlayBackground.png`,
      }}
      {...props}
    />
  );
};

export const GrantedLogo = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/grantedLogo.png`,
      }}
      {...props}
    />
  );
};

export const AnthemCardLogo = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/card-logos/anthemHiRes.png`,
      }}
      width={140}
      height={22}
      {...props}
    />
  );
};

export const MedicaCardLogo = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/card-logos/medicaHiRes.png`,
      }}
      width={120}
      height={22}
      {...props}
    />
  );
};

export const AetnaCardLogo = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/card-logos/aetnaHiRes.png`,
      }}
      width={107}
      height={22}
      {...props}
    />
  );
};

export const BackgroundBlueGradient = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/backgroundBlueGradient.png`,
      }}
      width="100%"
      height={300}
      {...props}
    />
  );
};

export const InsuranceCardImageBlock = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/insuranceCardImageBlock.png`,
      }}
      height={400}
      width={400}
      mb={-100}
      objectFit="contain"
      resizeMode="contain"
      {...props}
    />
  );
};

export const AppIcon = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/icon.png`,
      }}
      objectFit="contain"
      width={100}
      height={100}
      {...props}
    />
  );
};

export const FacebookIcon = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/logos/facebook.png`,
      }}
      objectFit="contain"
      width={100}
      height={100}
      {...props}
    />
  );
};

export const InstagramIcon = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/logos/instagram.png`,
      }}
      objectFit="contain"
      width={100}
      height={100}
      {...props}
    />
  );
};

export const GoogleIcon = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/logos/google.png`,
      }}
      objectFit="contain"
      width={100}
      height={100}
      {...props}
    />
  );
};

export const AppleAppStoreIcon = (props: Omit<ImageProps, "source">) => {
  return (
    <Image
      source={{
        uri: `/static/logos/apple-app-store.png`,
      }}
      objectFit="contain"
      width={100}
      height={100}
      {...props}
    />
  );
};
