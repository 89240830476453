import { useEffect } from "react";

import { singularService } from "@medbillai/app/lib/singular/singular";
import { type SingularConfigProps } from "@medbillai/app/lib/singular/types";

export function useSingularService() {
  // Initialize singular service on mount (client-side only)
  useEffect(() => {
    // Double-check that we're in the browser
    if (typeof window === "undefined") {
      return;
    }

    if (
      process.env.NEXT_PUBLIC_SINGULAR_SDK_KEY &&
      process.env.NEXT_PUBLIC_SINGULAR_SDK_SECRET
    ) {
      const config: SingularConfigProps = {
        singularSdkKey: process.env.NEXT_PUBLIC_SINGULAR_SDK_KEY,
        singularSdkSecret: process.env.NEXT_PUBLIC_SINGULAR_SDK_SECRET,
        singularFacebookAppId: "", // Not needed for web implementation
        singularTrackingEnabled:
          process.env.NEXT_PUBLIC_SINGULAR_TRACKING_ENABLED === "true",
      };
      singularService.initialize(config);
    } else {
      console.error("Singular SDK configuration is missing.");
    }
  }, []);
}
