import type {
  LDContext,
  LDMultiKindContext,
  LDSingleKindContext,
  LDUser,
} from "@launchdarkly/js-client-sdk-common";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { retry } from "ts-retry-promise";

/** Logger for use by LaunchDarkly clients. */
export const ldLogger = {
  // Disable debug and info logging, as they are excessively noisy
  debug: () => {},
  info: () => {},
  warn: console.warn,
  error: console.error,
};

export type TypeOfResult =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "object"
  | "function";

const isLDUser = (context: LDContext): context is LDUser =>
  !("kind" in context);
const isLDSingleKindContext = (
  context: LDContext,
): context is LDSingleKindContext =>
  "kind" in context && context.kind !== "multi";

export type LDIdentifyFunction = (
  context: LDContext | ((prevContext?: LDContext) => LDContext),
) => Promise<void>;

/** Add some context to existing LDContext, upgrading existing context into an
 * LDMultiContext if needed. */
export const updateKind =
  (multiUpdate: Omit<LDMultiKindContext, "kind">) =>
  (prevContext?: LDContext): LDContext => {
    const base: LDMultiKindContext = { kind: "multi", ...multiUpdate };
    if (!prevContext) {
      return base;
    } else if (isLDUser(prevContext)) {
      return { user: prevContext, ...base };
    } else if (isLDSingleKindContext(prevContext)) {
      const { kind: prevKind, ...prevRest } = prevContext;
      return { [prevKind]: prevRest, ...base };
    } else {
      // LDMultiContext
      return { ...prevContext, ...base };
    }
  };

export type FlagProviderProps = {
  children?: React.ReactNode;
  sdkKey?: string;
};

type FlagContextType = {
  isReady: boolean;
  startIdentify: () => void;
  finishIdentify: () => void;
};

export const FlagContext = createContext<FlagContextType>({
  isReady: true,
  startIdentify: () => {},
  finishIdentify: () => {},
});

/**
 * Do not use this directly, use `useFlagContextIsReady` hook instead.
 * @deprecated
 */
export const useFlagContext = () => useContext(FlagContext);
/**
 * Stateful hook that indicates when the flag context is ready (i.e. there are
 * no in-flight identify calls).
 *
 * The primary use case is for understanding when a flag variation is usable if
 * the flag is being evaluated immediately after a user has logged in (e.g. to
 * modify the onboarding flow).
 *
 * Use the resulting boolean value to gate usage of a given flag variation, e.g.
 * avoiding activating a `useEffect` that references the flag until the hook
 * result returns `true`.
 *
 * @returns `true` when the flag context is ready, `false` otherwise.
 */
export const useFlagContextIsReady = () => useFlagContext().isReady;

// Timeout for waiting for LD identify to complete.
//
// It's possible for the client to not be able to connect to LaunchDarkly in
// some circumstances, or otherwise time out. In that case, we want to wait a
// maximum amount of time before giving up and saying things are "ready", in
// case UX is blocked.
export const READY_TIMEOUT_MS = 2 * 1000;

// Standard setup that can be used by both the native and web implementations.
// This is somewhat over-complicated by the fact that multiple identify calls
// can arrive in an overlapping manner, so we don't want to signal readiness
// until all of them have completed.
export const useFlagContextSetup = () => {
  const [isReady, setIsReady] = useState(false);

  // We utilize a "singleton" timeout, in case multiple identify calls happen in
  // quick succession, so that we can just extend the timeout based on the
  // latest call.
  const readyTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const cancelReadyTimeout = useCallback(() => {
    if (readyTimeoutRef.current) {
      clearTimeout(readyTimeoutRef.current);
    }
  }, []);
  const startReadyTimeout = useCallback(() => {
    if (readyTimeoutRef.current) {
      clearTimeout(readyTimeoutRef.current);
    }

    readyTimeoutRef.current = setTimeout(() => {
      // TODO: Possibly drop this to log if Sentry is too noisy.
      console.warn("[Flag] Identify took too long, setting ready state");
      setIsReady(true);
    }, READY_TIMEOUT_MS);
  }, []);

  // Similarly, we want to keep track of multiple parallel identify calls, and
  // only set the ready state once all of them have completed.
  const identifyCountRef = useRef(0);
  const startIdentify = useCallback(() => {
    if (identifyCountRef.current === 0) {
      setIsReady(false);
    }
    identifyCountRef.current++;
    startReadyTimeout();
  }, [startReadyTimeout, setIsReady]);
  const finishIdentify = useCallback(
    () =>
      setTimeout(() => {
        identifyCountRef.current--;
        if (identifyCountRef.current <= 0) {
          setIsReady(true);
          cancelReadyTimeout();
          identifyCountRef.current = 0;
        }
      }, 10),
    [setIsReady, cancelReadyTimeout],
  );

  // Clean up the timeout when the provider unmounts.
  useEffect(() => {
    return () => {
      cancelReadyTimeout();
    };
  }, [cancelReadyTimeout]);

  return { isReady, startIdentify, finishIdentify };
};

/**
 * Retry identifying the user to LaunchDarkly, with a timeout and retries.
 * Intended for internal use by the native and web implementations.
 */
export const retryIdentify = async (identify: () => Promise<void>) => {
  try {
    await retry(identify, {
      timeout: 30 * 1000,
      retries: 2,
    });
  } catch (error) {
    console.warn(`Failed to identify LaunchDarkly user: ${error}`);
  }
};
