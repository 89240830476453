import { useMutation } from "@apollo/client";
import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { v4 as uuid } from "uuid";

import { Participant, gql } from "@medbillai/graphql-types";

import { errorHandling } from "../../lib/apollo/utils";

const createMultiFileDocumentMutation = gql(/* GraphQL */ `
  mutation CreateMultiFileDocument($input: CreateMultiFileDocumentInput!) {
    createMultiFileDocument(input: $input) {
      document {
        id
      }
      files {
        s3Key
        url
        name
      }
    }
  }
`);

const finalizeDocumentMutation = gql(/* GraphQL */ `
  mutation FinalizeDocument($input: FinalizeDocumentInput!) {
    finalizeDocument(input: $input) {
      document {
        id
      }
    }
  }
`);

export type Upload = {
  id: string;
  fileCount: number;
  contentType?: string;
};

export type UploadFile = {
  name: string;
  sizeBytes?: number;
  contentType: string;
  blob: Blob;
};

export type UploadInput = {
  issueId: string;
  uploadFiles: UploadFile[];
  onUploadComplete: (issueId: string) => Promise<unknown>;
  onUploadStarted: () => void;
  onUploadError: (error: UploadError) => void;
};

export interface UploadDocumentContextInterface {
  uploads: Upload[];
  upload: (uploadInput: UploadInput) => Promise<void>;
}

export const initialContext: UploadDocumentContextInterface = {
  uploads: [],
  upload: () => {
    throw new Error("Context not initialized");
  },
};

export const UploadDocumentContext =
  createContext<UploadDocumentContextInterface>(initialContext);

export interface UploadDocumentProviderProps {
  children?: React.ReactNode;
}

export type UploadError = {
  uploadId?: string;
  // User-friendly message to display to the user.
  message?: string;
  // The actual error that occurred.
  error?: Error;
};

/*
 * A high level provider for document uploading
 * Its purpose is to:
 * - Keep track of the upload status of documents.
 * - Enable parallel uploads of multiple documents
 *   and ensure a process in the background even if
 *   the user navigates away from the screen where the upload
 *   is initiated.
 */
export const UploadDocumentProvider = ({
  children,
}: UploadDocumentProviderProps) => {
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [createMultiFileDocument] = useMutation(
    createMultiFileDocumentMutation,
    {
      context: errorHandling("no-log"),
      ignoreResults: true,
    },
  );
  const [finalizeDocument] = useMutation(finalizeDocumentMutation, {
    context: errorHandling("no-log"),
    ignoreResults: true,
  });

  // Careful here to only pass updater methods to the state `set`.
  // Each uploads calling this method closes over the current state
  // and we don't want to reset the state to a stale value.
  const addUpload = useCallback((newUpload: Upload) => {
    setUploads(curr => [...curr, newUpload]);
  }, []);

  const removeUpload = useCallback((uploadId: string) => {
    setUploads(curr => curr.filter(upload => upload.id !== uploadId));
  }, []);

  const upload = useCallback(
    async ({
      uploadFiles,
      issueId,
      onUploadComplete,
      onUploadError,
    }: UploadInput) => {
      if (uploadFiles.length === 0) {
        throw new Error("No files to upload");
      }
      const uploadId = uuid().toString();

      addUpload({
        id: uploadId,
        fileCount: uploadFiles.length,
        contentType: uploadFiles[0]?.contentType,
      });

      try {
        const { data } = await createMultiFileDocument({
          variables: {
            input: {
              // Guaranteed with the above check; default to first file name.
              name: uploadFiles[0]!.name,
              files: uploadFiles.map(file => ({
                name: file.name,
                sizeBytes: file.sizeBytes,
                contentType: file.contentType,
              })),
              participant: Participant.USER,
            },
          },
        });
        if (!data || !data.createMultiFileDocument) {
          onUploadError({
            error: new Error("Unexpected empty document from server"),
          });
          return;
        }

        const { document, files } = data.createMultiFileDocument;

        for (const file of files) {
          // We know we'll find the file, as we just created it. It would have
          // errored out before if it didn't resolve.
          const uploadFile = uploadFiles.find(f => f.name === file.name);
          await fetch(file.url, {
            method: "PUT",
            body: uploadFile!.blob,
            headers: {
              "Content-Type": uploadFile!.contentType,
            },
          });
        }

        await finalizeDocument({
          variables: {
            input: {
              documentId: document.id,
              issueId,
            },
          },
        });
        // Await to prevent finally from
        // removing upload before its returned from graphql
        // to improve loading of the new entry when changing from
        // upload -> event.
        await onUploadComplete(issueId);
      } catch (error) {
        if (error instanceof Error) {
          onUploadError({
            uploadId,
            error,
          });
        } else {
          onUploadError({
            uploadId,
            error: new Error("Unknown error"),
          });
        }
      } finally {
        removeUpload(uploadId);
      }
    },
    [addUpload, createMultiFileDocument, finalizeDocument, removeUpload],
  );

  const contextValue = useMemo<UploadDocumentContextInterface>(
    () => ({
      uploads,
      upload,
    }),
    [uploads, upload],
  );

  return (
    <UploadDocumentContext.Provider value={contextValue}>
      {children}
    </UploadDocumentContext.Provider>
  );
};

export const useDocumentUpload = (
  context = UploadDocumentContext,
): UploadDocumentContextInterface => useContext(context);
