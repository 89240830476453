import { type Storage, type StorageOptions } from "./types";

export class WebStorage implements Storage {
  private namespace: string;

  constructor(options: StorageOptions) {
    this.namespace = options.namespace;
  }

  private checkAvailable() {
    if (typeof window === "undefined" || !window.localStorage) {
      console.warn("WebStorage is not available");
      throw new Error("Window is not available for localStorage");
    }
    return true;
  }

  private getFullKey(key: string): string {
    return `${this.namespace}-${key}`;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async set(key: string, value: string): Promise<void> {
    if (!this.checkAvailable()) {
      return;
    }
    localStorage.setItem(this.getFullKey(key), value);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async get(key: string): Promise<string | null> {
    if (!this.checkAvailable()) {
      return null;
    }
    return localStorage.getItem(this.getFullKey(key));
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async remove(key: string): Promise<void> {
    if (!this.checkAvailable()) {
      return;
    }
    localStorage.removeItem(this.getFullKey(key));
  }
}

export const createStorage = (options: StorageOptions): Storage => {
  return new WebStorage(options);
};
