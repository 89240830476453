"use client";

import { useEffect, useState } from "react";
import { Linking } from "react-native";

import { X as XIcon } from "@tamagui/lucide-icons";

import {
  LoadingButton,
  Paragraph,
  Portal,
  Sheet,
  Subheadline,
  XStack,
  YStack,
  useSafeAreaInsets,
  useSheetContainer,
} from "@medbillai/ui";

import { useAuth } from "../lib/auth";
import { appStoreUrl } from "../lib/constants";
import { downloadStorage } from "../lib/storage";
import { AppIcon } from "./local-images";

const STORAGE_KEY = "has-shown-download-prompt";

const checkLocalStorage = async (): Promise<boolean | null> => {
  try {
    const value = await downloadStorage.get(STORAGE_KEY);
    return value === "true";
  } catch (e) {
    console.warn("Failed to access storage for download prompt:", e);
    return null;
  }
};

export function DownloadAppSheet() {
  const { bottom } = useSafeAreaInsets();
  const containerComponent = useSheetContainer();
  const { isAuthenticated } = useAuth();

  const [isClientReady, setIsClientReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hasShownPrompt, setHasShownPrompt] = useState<boolean | null>(null);

  // Update the initialization effect to handle async storage check
  useEffect(() => {
    let checkTimeoutDelayed: NodeJS.Timeout;

    const checkStorage = async () => {
      const hasShown = await checkLocalStorage();

      if (hasShown !== null) {
        setHasShownPrompt(hasShown);
        setIsClientReady(true);
      } else {
        // If storage wasn't available, set up a timeout to check again.
        checkTimeoutDelayed = setTimeout(async () => {
          const check = await checkLocalStorage();
          if (check !== null) {
            setHasShownPrompt(check);
            setIsClientReady(true);
            clearInterval(checkTimeoutDelayed);
          }
        }, 1000);
      }
    };

    void checkStorage();

    // Clean up interval if component unmounts
    return () => {
      if (checkTimeoutDelayed) {
        clearTimeout(checkTimeoutDelayed);
      }
    };
  }, []);

  // Handle iOS detection and timer
  useEffect(() => {
    if (
      !isClientReady ||
      !isAuthenticated ||
      hasShownPrompt ||
      typeof window === "undefined" ||
      !/iPhone|iPad|iPod/.test(navigator.userAgent)
    ) {
      return;
    }

    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, [isClientReady, isAuthenticated, hasShownPrompt]);

  const handleClose = async () => {
    setIsOpen(false);
    setHasShownPrompt(true);

    try {
      await downloadStorage.set(STORAGE_KEY, "true");
    } catch (e) {
      console.warn("Failed to save to storage for download prompt:", e);
    }
  };

  // Don't render anything until client is ready
  if (!isClientReady) {
    return null;
  }

  return (
    <Portal>
      <Sheet
        modal={true}
        snapPointsMode="fit"
        dismissOnSnapToBottom
        open={isOpen}
        onOpenChange={handleClose}
        animation="medium"
        zIndex={100_000}
        native
        containerComponent={containerComponent}
      >
        <Sheet.Overlay
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          animation="medium"
        />
        <Sheet.Frame
          mb={bottom}
          borderBottomWidth={0}
          borderTopLeftRadius="$8"
          borderTopRightRadius="$8"
        >
          <XStack justifyContent="space-between" alignItems="center" p="$4.5">
            <YStack width="$2"></YStack>
            <YStack
              width="$2"
              animation="bouncyTight"
              pressStyle={{
                scale: 0.9,
              }}
              onPress={handleClose}
            >
              <XIcon width="$2" strokeWidth={2} color="$textSecondary" />
            </YStack>
          </XStack>
          <YStack
            justifyContent="center"
            alignItems="center"
            px="$4.5"
            pb="$6"
            gap="$6"
          >
            <XStack
              borderRadius="$6"
              shadowOffset={{ width: 0, height: 4 }}
              shadowRadius={8}
              shadowColor="$shadowColor"
            >
              <AppIcon height={80} width={80} borderRadius="$6" />
            </XStack>
            <YStack gap="$2">
              <Subheadline textAlign="center">
                {"The best way to manage \nyour health insurance."}
              </Subheadline>
              <Paragraph textAlign="center" theme="alt1">
                Get the best experience by using our app
              </Paragraph>
            </YStack>
            <LoadingButton
              onPress={() => {
                void Linking.openURL(appStoreUrl);
              }}
            >
              Download The App
            </LoadingButton>
          </YStack>
        </Sheet.Frame>
      </Sheet>
    </Portal>
  );
}
