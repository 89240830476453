import { type PropsWithChildren, useMemo } from "react";

export const useSheetContainer = () => {
  return useMemo(
    () =>
      ({ children }: PropsWithChildren) =>
        children,
    [],
  );
};
