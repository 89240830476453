// Import types but defer loading the actual SDK
import type {
  SingularConfig,
  singularSdk as SingularSdkType,
} from "singular-sdk";

import {
  type SingularConfigProps,
  type SingularEventArgs,
  type SingularEventName,
} from "./types";

// Check if we're in a browser environment
const isBrowser = typeof window !== "undefined";

// Load SDK only in browser
let singularSdk: typeof SingularSdkType | undefined;
let SingularConfigClass: typeof SingularConfig | undefined;

// Type-safe module loading
if (isBrowser) {
  void (async () => {
    try {
      const singularModule = await import("singular-sdk");

      if (
        singularModule &&
        "singularSdk" in singularModule &&
        "SingularConfig" in singularModule
      ) {
        singularSdk = singularModule.singularSdk;
        SingularConfigClass = singularModule.SingularConfig;
      } else {
        console.error("[SINGULAR]: Invalid module format");
      }
    } catch (error) {
      console.error("Failed to load Singular SDK:", error);
    }
  })();
}

class SingularService {
  private initialized = false;
  private userId: string | undefined = undefined;
  private singularTrackingEnabled: boolean = false;

  initialize(config: SingularConfigProps): void {
    // Early return if not in browser or tracking disabled
    if (!isBrowser || config.singularTrackingEnabled === false) {
      console.log(
        "[SINGULAR]: Skipping initialization (not in browser or tracking disabled)",
      );
      this.singularTrackingEnabled = false;
      return;
    }

    // Check if SDK loaded properly
    if (!singularSdk || !SingularConfigClass) {
      console.error("[SINGULAR]: SDK not loaded properly");
      return;
    }

    if (this.initialized) {
      console.warn("[SINGULAR]: Already initialized");
      return;
    }

    const singularConfig = new SingularConfigClass(
      config.singularSdkKey,
      config.singularSdkSecret,
      "com.granted.app",
    );
    // Set session timeout equivalent to the native 180 seconds
    singularConfig.withSessionTimeoutInMinutes(3);

    if (this.userId != undefined) {
      singularConfig.withCustomUserId(this.userId);
    }

    singularSdk.init(singularConfig);
    this.initialized = true;
    this.singularTrackingEnabled = true;
  }

  setUserId(userId: string) {
    if (!isBrowser || !singularSdk) {
      return;
    }

    if (!this.initialized && this.singularTrackingEnabled) {
      console.warn(
        "[SINGULAR]: Not initialized before setting userId, storing user id on service till initialization",
      );
    }
    this.userId = userId;
    if (this.singularTrackingEnabled) {
      singularSdk.login(userId);
    }
  }

  resetUserId() {
    if (!isBrowser || !singularSdk) {
      return;
    }

    if (!this.initialized && this.singularTrackingEnabled) {
      console.warn("[SINGULAR]: Not initialized before resetting userId");
    }
    this.userId = undefined;
    if (this.singularTrackingEnabled) {
      singularSdk.logout();
    }
  }

  event(eventName: SingularEventName, args?: SingularEventArgs) {
    if (!isBrowser || !singularSdk) {
      return;
    }

    if (!this.initialized && this.singularTrackingEnabled) {
      console.warn("[SINGULAR]: Not initialized before sending events");
    }

    if (!this.singularTrackingEnabled) {
      return;
    }

    if (args) {
      singularSdk.event(eventName, args);
    } else {
      singularSdk.event(eventName);
    }
  }
}

const singularService = new SingularService();
export { singularService };
